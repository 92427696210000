/* GENERAL */

.centered-txt {
    justify-content: center;
    text-align: center;  
}

.centered-el{
    margin: 0 auto;
}

/* UPPER TOP SECTION ----------------------------------------------------------- */

.h-container {
    width: 100%;
    height: 60%;
    position: relative;
    background-image: url(resources/images/bedroom/bedroom_image-min_crop.jpg);
    background-repeat: no-repeat;
    background-size: cover;
}



/* APP BAR */
#bar-cont{
    height: fit-content;
    width: fit-content;
    display: table;
    table-layout: fixed;
    border-spacing: 5px;
    position: absolute;
    top: 3%;
    left: 7.7%;
}

.barRows {
    display: table-row;
    text-align: center;


      


}

.appbar {
    display: table-cell;

    vertical-align: middle;
    background-color: white;
    height: 5px;
    width: 40px;
    border-radius: 25px;
}


#menu {
    color: white;
    font-family: 'Poppins-light';
}


/* -----Slider Dots */
.dot {
    color: white;
    font-size: 65px;
    font-size: 16.5vw;
    
}


#sliderDots{
    position: absolute;
    left: 50%;
    bottom: 5%;
    transform: translateX(-50%); 
    
}


/* UPPER TOP SECTION ----------------------------------------------------------- */

.h-title {
    font-size: 26.5px;
    font-size: 7vw;
    margin: 0 auto 15px;
    padding-top: 1%;
    line-height: 1.86;
    white-space: nowrap;
}

.h-body {
    font-size: 16px;
    font-size: 4vw;
    margin: 0px 10px 25.5px 10px;
    padding: auto 30px;
    font-family: var(--secondary-font);
    line-height: 1.71;
    text-justify: inter-word;
   
}

.shop-btn {
    width: 80vw;
    height: 10.5vw;
    font-size: 14px;
    font-size: 3.2vw;
    font-weight: 300;
    margin: 0px auto;
    margin-bottom: 20px;
    margin: 15.5px auto 0 auto;
    padding: 12.5px 78.5px 10.5px 78.5px;
    padding-bottom: 20px;
    border: solid 1px #f5f5f5;
    font-family: var(--secondary-font);
    line-height: 1.5;
    white-space: nowrap;

}

#h-body{
    font-family: var(--secondary-font); 
    width: 89.9%;
    height: 27.5%;
    font-size: 11px;
    line-height: 24px;
    font-weight: 300;
    letter-spacing: normal;
    position: relative;
    top: 30%;
    text-align: center;
    left: 50%;
    text-justify: inter-word;
    text-align: justify;
}

#btn-shop {
    width: 66.7%;
    font-size: 2.5vw;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    vertical-align: middle;
    text-justify: inter-word;
    font-family: 'Poppins-light';
    color: white;
    border: solid;
    border-color: white;
    top: 30%;
    padding: 8px;
}



/* Logo */
.logo {
    width: fit-content;
    height: fit-content;
    position: absolute; 
    top: 3%;
    right: 7.7%;
    /* font-family: ''; */
    border: solid 1px white;
    padding: 5.6px 5.4px 5.6px 6.5px;
    margin: 0px;
    font-size: 10.5vw;
    color: white;
}


/* UPPER MID SECTION */
.upper-mid-container {
    height: 100%;
    background-color: #f5f5f5;
    position: relative;
    margin-top: 0;
    
    
    /* border: solid red; */
}
.sect-2-title {
    font-size: 24px;
    font-size: 6vw;
    color: #000000;
    line-height: 2.27;
    padding-top: 10%;
   
}

.divider{  
    width: 22.9%;
    height: 6px;
    /* margin: 19px 102.5px 0 101.8px; */
    background-color:  #201e1e;
    margin: 2.45% auto 30%;
}

.backdrop {
    width: 85.5%;
    height: 69.7%;
   
    padding: 221px 21px 27.9px 137.5px;
    opacity: 0.83;
    background-color: #2c3e50;
    position: absolute;
    float: right;
    bottom: 50px;
    right: 0;
}


.grid {
 
  width: fit-content;

  background-color: transparent;
  display: grid;
  grid-template-columns: auto auto;
  /* grid-row: auto auto; */
  grid-column-gap: 15px;
  grid-row-gap: 10px;
  position: relative;
  bottom: 85px;
  left: 30px;
  /* bottom: 200px; */
  /* position: relative;
  bottom: 85%;
  left: 5.3%; */
  /* position: absolute;
  top: 20%;
  left: 5.6%; */


}
/* Category- */



.comp-container 
{
    position: relative;
    width: fit-content;
    height: fit-content;
    min-height: fit-content;
    padding: 10.1px 8px 15px;
    background-color: white;

}

#card-img {
    width: 146.1px;
    height: 148.6px;
    padding: 7.9px 5.8px 2.1px 5.8px;
    margin: 0 auto;

}

#inside-border {
    width: 120.5px;
    height: 124.3px;
    /* padding: 7.9px 5.8px 7.9px 5.8px; */
    margin: 0px 4.3px 0px 0px;
    border: solid 1px white;
    position: absolute;
    top: 10.7%;
    left: 12%
    /* bottom: 140px; */

}
#card-category {
    width: 72px;
    height: 21px;
    margin: auto 42.5px auto 31.6px;
    font-family: 'PlayfairDisplay-Roman';
    font-size: 16px;
    line-height: 2.63;
    text-align: center;
    color: #121212;
    white-space: nowrap;

}

#card-btn {
    width: 108.5px;
    height: 10.2%;
    padding: 3.5px 28.7px 6.5px 27.7px;
    margin: 6.8% auto;
    border: solid 1px #252424;
    font-family: 'Poppins-light';
    font-size: 12px;
    text-align: center;
    text-justify: inter-word;
    white-space: nowrap;
    color: #222020;
}


/* New Arrivals Section */

.A-card {
    grid-row: 1 / span 1;
    grid-column: 1 / span 2;
    border: solid green 3px;
  

}

.img-container{
    display: block;
    width: 87.9%;
    height: 219.7px;   
    margin: 0 auto;
    /* border: solid red; */
    position: relative;

}


.A-card-txt1 {
    width: 117px;
    height: 17px;
    position: absolute;
    /* top: 82.5%; */
    bottom: 22%;
    left: 3.4%;
    font-family: 'Poppins';
    font-size: 15px;
    font-weight: 300;
    line-height: 1.5;  
    text-align: left;
    color: white;
    white-space: nowrap;
}

.A-card-txt2 {
    width: 167px;
    height: 32px;
    position: absolute;
    bottom: 10%;
    left: 3.4%;
    font-size: 25px; 
    line-height: 2.29; 
    text-align: left;
    color: #ffffff;
    white-space: nowrap;
}

/* Discounts Section */

.D-img-container {
    width: 43.2%;
    height: 100%;
    position: relative;
    text-align: center;
}

.D-card-txt1 {
    height: 21px; 
    margin: 0 0 6.5px;
    white-space: nowrap;
    color: white;
    font-size: 2.5vh;
    position: absolute;
}

.d-card {
    border: solid blue;
    width: 43.2%;
    /* height: 318px; */
    height: 100%;
    grid-row-start: 2;
    grid-column-start: 1;
    grid-column-end: 3;
}
/* f-card*/

.f-card {
    width: 43.2%;
    height: 50%;
    border: solid pink 3px;
    /* background-size: cover; */
    grid-row-start: 2;
    grid-column-start: 2;
    grid-column-end: 4;
}

@media only screen and (min-width: 768px){

    #bar-cont{
        display: none;
    }

    .comp-container 
{
    width: 257px;

}

    
}