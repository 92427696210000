@font-face {
  font-family: 'Playfair';
  src: url('resources/fonts/playfair-display/PlayfairDisplay-Regular.ttf');
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Playfair-Black';
  src: url('resources/fonts/playfair-display/PlayfairDisplay-Black.ttf');
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins-light';
  src: url('resources/fonts/Poppins/Poppins-Light.ttf');
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url('resources/fonts/Poppins/Poppins-Regular.ttf');
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
}

:root { 
  --primary-font: 'Playfair';
  --secondary-font: 'Poppins'
  
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: var(--primary-font);
}

body, html, #root {
  width: 100%;
  height: 100%;
  font-size: 16px;
  /* overflow-y: scroll; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}



/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */
